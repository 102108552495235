<template>
  <div class="welcome" data-testId="news-dashboard-welcome">
    <div class="welcome__wrapper">
      <img class="welcome__img" :src="dpoImage" />
      <div class="welcome__text">
        <h1 class="welcome__title">{{ $t("news.title") }}</h1>
        <p class="welcome__description">{{ $t("news.info") }}</p>
        <span>- {{ dpoFullName }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "NewsHeroSection",
  computed: {
    ...mapGetters(["companyData"]),
    dpoImage() {
      return `data:image/png;base64, ${this.companyData.main_contact_dpo.image}`;
    },
    dpoFullName() {
      const { first_name, last_name } = this.companyData.main_contact_dpo;
      return `${first_name} ${last_name}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.welcome {
  background: #f2f4f6;
  padding: 30px 0;

  .welcome__wrapper {
    background: #fff;
    max-width: 1440px;
    min-height: 270px;
    max-height: 350px;
    margin: 0 auto;
    border-radius: 4px;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);
    display: flex;

    .welcome__text {
      -ms-flex: 1; /* IE 10 */
      flex: 1;
      min-height: 50px;
      padding: 40px;
      color: #003349;
      width: calc(100% - 300px);
    }

    .welcome__title {
      display: block;
      margin-bottom: 20px;
    }

    .welcome__description {
      overflow: hidden;
      max-height: 210px;
    }

    .welcome__img {
      width: 300px;
      object-fit: cover;
      object-position: center;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .welcome__wrapper {
    min-height: auto;
    margin: 0 10px;

    .welcome__img {
      display: none;
    }

    .welcome__text {
      width: 100%;
    }
  }
}
</style>
